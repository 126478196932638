import request from '@utils/fetch'
import axios from 'axios'

// 登录接口
export function login ({ username, password }) {
  return axios({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    baseURL: process.env.VUE_APP_LOGIN,
    // baseURL: 'http://172.18.132.159:8422',
    // baseURL: 'http://47.118.23.224:8421/oauth',
    
    url: '/oauth/token',
    method: 'post',
    data: {
      username: username + ',9 ',
      password: password,
      grant_type: 'password',
      client_id: '2019060110331823',
      client_secret: '4HBtqmIpNyF8NEvUoi4uCfCZfhc='
    },
    transformRequest: [function (data) {
      let ret = ''
      for (const it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      }
      return ret
    }]
  })
}

// 验证token，保证同一账号只能在一台电脑登录
export function verifyToken () {
  return request({
    url: '/login/verifyToken',
    method: 'get',
    params: {
      userType: 9 
    }
  })
}

export function getUser (data) {
  return request({
    url: '/login/getUser',
    method: 'post',
    data
  })
}
